var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "users-page" }, [
    _c("section", [
      _c("div", { staticClass: "navbar bg-white p-3" }, [
        _c(
          "div",
          { staticClass: "container text-center" },
          [
            _c("mega-input", {
              staticClass: "mb-0",
              attrs: {
                placeholder: "email@mail.ru",
                button: { icon: "i-search", click: _vm.onSearch }
              },
              on: { keyup: _vm.onKeyUp },
              model: {
                value: _vm.query,
                callback: function($$v) {
                  _vm.query = $$v
                },
                expression: "query"
              }
            }),
            _vm._v(" "),
            _c(
              "mega-button",
              {
                staticClass: "ml-auto p-0",
                class:
                  _vm.filter.order === "id_asc" ? "btn-primary" : "btn-white",
                on: {
                  click: function($event) {
                    _vm.filter.order = "id_asc"
                  }
                }
              },
              [_c("i", { staticClass: "i-arrow-up" })]
            ),
            _vm._v(" "),
            _c(
              "mega-button",
              {
                staticClass: "p-0",
                class:
                  _vm.filter.order === "id_desc" ? "btn-primary" : "btn-white",
                on: {
                  click: function($event) {
                    _vm.filter.order = "id_desc"
                  }
                }
              },
              [_c("i", { staticClass: "i-arrow-down" })]
            )
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("section", [
      _c("div", { staticClass: "container py-5" }, [
        _vm.loading && _vm.users.length === 0
          ? _c(
              "div",
              { staticClass: "row" },
              _vm._l(16, function(i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass:
                      "sm-down:col-12 md:col-6 lg:col-4 xl:col-3 mb-4"
                  },
                  [_c("placeholder-user-card")],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row align-items-stretch" },
          _vm._l(_vm.users, function(user, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "sm-down:col-12 md:col-6 lg:col-4 xl:col-3 mb-4"
              },
              [_c("user-card", { key: i, attrs: { data: user } })],
              1
            )
          }),
          0
        )
      ])
    ]),
    _vm._v(" "),
    _c("section", [
      _vm.loading
        ? _c("div", { staticClass: "container py-5 text-center" }, [
            _c(
              "span",
              { staticClass: "btn btn-info opacity-50 px-6 loading" },
              [_vm._v("Loading")]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }