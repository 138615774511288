var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mega-card", { staticClass: "rounded h-100 mb-0" }, [
    _c("div", { staticClass: "navbar bg-white border-bottom rounded-top" }, [
      _c("div", { staticClass: "container-fluid py-3" }, [
        _c(
          "div",
          { staticClass: "w-100 navbar-label mr-auto text-dark" },
          [
            _c("mega-image", {
              staticClass: "rounded-full navbar-avatar mr-3",
              attrs: { small: "", src: "..." }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("small", { staticClass: "strong" }, [
                _c("span", [_vm._v("_______ ____________")])
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v("____________")
              ])
            ])
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("nav", { staticClass: "nav flex-column font-small w-100" }, [
      _c("div", { staticClass: "nav-text text-muted" }, [
        _c("i", { staticClass: "i-mail" }),
        _vm._v(" "),
        _c("span", [_vm._v("- - -")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "nav-text text-muted" }, [
        _c("i", { staticClass: "i-gift" }),
        _vm._v(" "),
        _c("span", [_vm._v("- - -")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "nav-text text-muted" }, [
        _c("i", { staticClass: "i-user-plus" }),
        _vm._v(" "),
        _c("span", [_vm._v("- - -")])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }