<template>
    <main class="users-page">

        <section>
            <div class="navbar bg-white p-3">
                <div class="container text-center">
                    <mega-input class="mb-0" @keyup="onKeyUp" placeholder="email@mail.ru" v-model="query" :button="{icon: 'i-search', click: onSearch}"/>

                    <!--                    <mega-select class="mb-0 ml-2"-->
                    <!--                                 placeholder="Type is not select"-->
                    <!--                                 v-model="filter.sex"-->
                    <!--                                 :options="sexTypes"/>-->

                    <mega-button class="ml-auto p-0" @click="filter.order = 'id_asc'" :class="filter.order === 'id_asc' ? 'btn-primary' : 'btn-white'"><i class="i-arrow-up"></i></mega-button>
                    <mega-button class="p-0" @click="filter.order = 'id_desc'" :class="filter.order === 'id_desc' ? 'btn-primary' : 'btn-white'"><i class="i-arrow-down"></i></mega-button>
                </div>
            </div>
        </section>

        <section>
            <div class="container py-5">

                <div class="row" v-if="loading && users.length === 0">
                    <div class="sm-down:col-12 md:col-6 lg:col-4 xl:col-3 mb-4" v-for="i in 16" :key="i">
                        <placeholder-user-card/>
                    </div>
                </div>

                <div class="row align-items-stretch">
                    <div class="sm-down:col-12 md:col-6 lg:col-4 xl:col-3 mb-4" v-for="(user, i) in users" :key="i">
                        <user-card :data="user" :key="i"/>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container py-5 text-center" v-if="loading">
                <span class="btn btn-info opacity-50 px-6 loading">Loading</span>
            </div>
        </section>
    </main>
</template>

<script>
    import UserCard from './components/user-card'
    import PlaceholderUserCard from '../../placeholders/user-card'
    // import SexTypes from '../../../data/sex_types'

    export default {
        name: 'UsersPage',
        data() {
            return {
                users: [],
                loadCount: 50,
                page: 0,
                length: 0,
                loading: false,
                query: '',
                sexTypes: [{"value": 0, "name": "All"}],
                orderTypes: [{"value": 'id_desc', "name": "From Last"}, {"value": 'id_asc', "name": "From First"}],
                filter:{
                    search: '',
                    order: 'id_desc',
                    sex: 0
                }
            }
        },
        computed: {
            onPage: {
                get() {
                    let cFrom = this.loadCount * (this.page-1 || 0);
                    let cTo = this.loadCount * (this.page || 1);

                    if(cTo > this.length) cTo = this.length;

                    return cFrom +' - '+cTo
                }
            }
        },
        watch: {
            page() {
                this.loadPage();
            },
            filter: {
                handler(){
                    this.users = [];
                    this.loadPage();
                },
                deep: true
            },
        },
        mounted() {
            this.$navbar.name = 'Users';

            window.addEventListener("scroll", () => { this.checkPosition() });
            window.addEventListener("resize", () => { this.checkPosition() });

            this.loadPage();
        },
        beforeDestroy() {
            document.removeEventListener("scroll", () => {}, false);
            document.removeEventListener("resize", () => {}, false);
        },
        methods: {

            onKeyUp(event) {
                if(event.key === 'Enter') this.onSearch();
            },

            checkPosition() {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight - 300;
                if (!this.loading && bottomOfWindow && this.page < Math.ceil(this.length / this.loadCount)) this.page++;
            },

            onSearch() {
                this.filter.search = this.query
            },

            loadPage() {
                this.loading = true;

                let params = {
                    order: this.filter.order,
                    limit: this.loadCount,
                    offset: this.loadCount * this.page
                };

                if(this.filter.search)
                    params.search = this.filter.search;

                this.$api.v1.get('/userList', {params})
                    .then(response => {
                        this.length = response.data.count;
                        this.loading = false;

                        this.$navbar.name = 'Users ' + '('+this.length+')';

                        if(response.data.users)
                            response.data.users.map(item => this.users.push(item));
                    })
            }
        },
        components: {
            UserCard,
            PlaceholderUserCard
        }
    }
</script>
